const COMPANY_FORM_ID = 'session_unique_id';
const storageApi = localStorage;

const updateStorage = (ev) => {
  storageApi.setItem(COMPANY_FORM_ID, ev.target.value);
};

const initSessionNew = () => {
  const cidInput = document.getElementById(COMPANY_FORM_ID);
  cidInput.addEventListener('change', updateStorage);

  // restore init value
  const cid = storageApi.getItem(COMPANY_FORM_ID);
  cidInput.value = cid;
}

document.addEventListener('DOMContentLoaded', initSessionNew);
document.addEventListener('turbolinks:load', initSessionNew);
